ul.post-list li.post {
    padding-bottom: 1.2em; 
    margin-bottom: 1em; 
    border-bottom: 0.4px solid #AAA;

    /* Different post body size depending on length */
    p.default {
        font-size: 1em;
    }
    p.medium {
        font-size: 1.6em;
    }
    p.large {
        font-size: 1.8em;
    }
}

/* Post pages */
div.post {
    p.default {
        font-size: 1em;
    }
    p.medium {
        font-size: 1.6em;
    }
    p.large {
        font-size: 1.8em;
    }

    .post-tag {
        padding: 0 .4rem;
        border-radius: .2rem;
        background-color: darken(#f9f9f9, 10);
        text-transform: capitalize;
    }
}

a.read-more {
    box-shadow: inset 0 -4px 0 #ffd671;
  }
  a.read-more:hover {
    box-shadow: inset 0 -4px 0 #fbeabf;
    text-decoration: none;
  }

a.bold-underline {
    text-decoration: none;
    background-image: linear-gradient(120deg, #ffd671 50%, #fae7b5 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.22em;
    background-position: 0 88%;
    transition: background-size 0.1s;
    &:hover {
        background-image: linear-gradient(120deg, #fcedc9 50%, #f9f1db 100%);
    }
}

.author {
    border-radius: .3em;
    padding: .4em 1.1em;
    background-color: rgba(242, 237, 231, 0.72);
}

@media (min-width: 38em) {
    .author {
        padding: .8em 2.2em;
    }
}

footer {
    display: flex;
    justify-content: center;
    row-gap: 1.6rem;
    flex-direction: column;

    div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }

    .social-icons a {
        transition: fill 200ms ease-in-out;
        &:hover {
            text-decoration: none;
            fill: #268bd2;
        }
    }
}

#preview-wrapper {
    height: 100%;
    border-top: .6em solid #268bd2;
    padding: 1em 2em;
    background-color: rgba(242,237,231,0.72);

    h1 {
        font-weight: bold;
    }

    .date {
        font-size: .8em;
    }

    .categories {
        font-size: .8em;
        color: #268bd2;
    }
}
